let shopHostname = window.location.hostname;
if (shopHostname.startsWith("client") || shopHostname.startsWith("localhost")) {
  shopHostname = "https://new.staging.carus-verlag.com";
} else {
  shopHostname = "https://www.carus-verlag.com";
}

export default {
  // product display information
  product: {
    name: "carus performance portal",
    version: "24.10.11.0",
    gitsha: "663f7044c9c9e1b44b385c9d6e230b5e00170849",
    shopHostname,
  },

};
